export const ROUTES = {
	SIGNIN: '/login',
	FORGET_PASSWORD: '/forgetPassword',
	NO_ROUTE: '/',
	BUSINESSES: '/businesses',
	ACTIVITIES: '/activities',
	REVENUE: '/revenue',
	API_DASHBOARD: '/api-dashboard',
	API_ROLE_PERMISSION: '/role-permission',
};

export const SIDEBAR_TAB = {
	BUSINESSES: 'Businesses',
	ACTIVITIES: 'Activities',
	REVENUE: 'Revenue',
	API_DASHBOARD: 'API Dashboard',
	ROLE_PERMISSION: 'Roles & permissions',
};

export const ACTIVE_TABS = {
	BUSINESSES: 'businesses',
	ACTIVITIES: 'activities',
	REVENUE: 'revenue',
	API_DASHBOARD: 'api-dashboard',
	API_ROLE_PERMISSION: 'role-permission',
};

export const SIDEBAR_TABS = {
	label: 'Main Menu',
	tabs: [
		{
			ROUTE: ACTIVE_TABS.BUSINESSES,
			TITLE: SIDEBAR_TAB.BUSINESSES,
			ICON: 'ri-profile-line',
			TAB_INDEX: 1,
		},
		{
			ROUTE: ACTIVE_TABS.ACTIVITIES,
			TITLE: SIDEBAR_TAB.ACTIVITIES,
			ICON: 'ri-list-check-2',
			TAB_INDEX: 2,
		},
		{
			ROUTE: ACTIVE_TABS.REVENUE,
			TITLE: SIDEBAR_TAB.REVENUE,
			ICON: 'ri-money-dollar-circle-fill',
			TAB_INDEX: 3,
		},
		{
			ROUTE: ACTIVE_TABS.API_DASHBOARD,
			TITLE: SIDEBAR_TAB.API_DASHBOARD,
			ICON: 'ri-database-line',
			TAB_INDEX: 4,
		},
		{
			ROUTE: ACTIVE_TABS.API_ROLE_PERMISSION,
			TITLE: SIDEBAR_TAB.ROLE_PERMISSION,
			ICON: 'ri-settings-5-line',
			TAB_INDEX: 5,
		},
	],
};

export const SIDEBAR_ACCOUNT_TABS = {
	label: 'Account',
	tabs: [
		{
			TITLE: 'Logout',
			ICON: 'ri-logout-circle-line',
			TAB_INDEX: 3,
		},
	],
};
