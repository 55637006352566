import { useRecoilState } from 'recoil';
import { ChangeEvent, useEffect } from 'react';

import { Input, TextArea } from '@storybook';
import {
	AddNewRoleFormState,
	SelectedUserRoleState,
} from 'views/role-permission/store';

export const UserInputRole = () => {
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const { name, description, formErrors } = addRoleForm ?? {};

	const [selectedUserRole] = useRecoilState(SelectedUserRoleState);

	useEffect(() => {
		if (selectedUserRole) {
			const { name, description } = selectedUserRole;

			if (name) {
				setAddRoleForm((prev) => ({
					...prev,
					name,
					formErrors: { ...prev.formErrors, name: null },
				}));
			}
			if (description) {
				setAddRoleForm((prev) => ({
					...prev,
					description,
					formErrors: { ...prev.formErrors, description: null },

				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeInput = (
		e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
		name: string
	) => {
		const { value } = e.target;
		const error = {...formErrors}
		if(name === 'name'){
			if (!value?.trim()) {
				error.name = 'Name is required.';
			} else if (value?.trim().length < 3) {
				error.name = 'Name should be at least 3 characters long.';
			} else if (!/^[a-zA-Z\s]+$/.test(value?.trim() || "")) {
				error.name = 'Only alphabets are allowed.';
			} else {
				error.name = null;
			}
		}

		if(name === 'description'){
			if (!value?.trim()) {
				error.description = 'Description is required.';
			} else {
				error.description = null;
			}
		}
		setAddRoleForm((pre) => ({ ...pre, [name]: value, formErrors: {...pre.formErrors, ...error} }));
	};

	return (
		<div className="AddNewRoleModal--container__inputRole">
			
			<div>
				<Input
					placeholder="Enter role name"
					label="Role name"
					inputType="text"
					handleChange={(e) => handleChangeInput(e, 'name')}
					value={name}
					isRequired
					errorMessage={formErrors.name}
					isError={!!formErrors.name}	
				/>
			</div>
			<div>
				<TextArea
					label="Description"
					handleChange={(e) => handleChangeInput(e, 'description')}
					placeholder="Enter description here..."
					value={description}
					row={3}
					isRequired
					errorMessage={formErrors.description}
					isError={!!formErrors.description}	
				/>
			</div>
		</div>
	);
};
