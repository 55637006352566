import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userToken } from 'states';
import { Dashboard, SignIn } from 'views';
import { ROUTES } from './constants';

export const AllRoutes = () => {
	const isUserToken = useRecoilValue(userToken);
	const setUserToken = useSetRecoilState(userToken);

	useEffect(() => {
		const tokenId = localStorage.getItem('token');
		if (tokenId !== 'undefined') {
			const parseToken = JSON.parse(tokenId);
			setUserToken(parseToken);
		}
	}, []);

	const { SIGNIN } = ROUTES;
	return (
		<>
			<Routes>
				{!isUserToken ? (
					<>
						<Route path={SIGNIN} element={<SignIn />} />
						<Route path="*" element={<Navigate to={SIGNIN} />} />
					</>
				) : (
					<>
						<Route path="*" element={<Dashboard />} />
						<Route path="/" element={<Dashboard />} />
					</>
				)}
			</Routes>
		</>
	);
};
