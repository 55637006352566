import { useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { RoleAndPermissionNavigation, UserRoleNavigation } from '../constants';
import {
	AddNewRoleFormState,
	IAddNewRoleFormError,
	IPermissionType,
	IReadWrite,
	PermissionNavigation,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
	UserRoleState,
} from '../store';
import { useRolepermission } from './use-role-permission';
import { useNotification } from 'hooks';
import { message } from 'constant';

type UseNewRole = {
	handleCloseModel: () => void;
};

export const useNewRoles = ({ handleCloseModel }: UseNewRole) => {
	//global state
	const [navigate, setNavigation] = useRecoilState(PermissionNavigation);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	const resetSelectedUserRole = useResetRecoilState(SelectedUserRoleState);
	const addRoleForm = useRecoilValue(AddNewRoleFormState);
	const setAddRoleForm = useSetRecoilState(AddNewRoleFormState);

	const setUserRoleState = useSetRecoilState(UserRoleState);
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);

	const [isOpenDeleteModal, setIsUserDeleteModal] = useState(false);
	const [selectedUserRole, setSelectedUserRole] = useState(null);
	const [createRoleLoading, setCreateRoleLoading] = useState(false);

	const { createUserRole, deleteUserRole } = useRolepermission();
	const { successNotification, errorNotification } = useNotification();
	const { name, description, isTemplate, permissions, formErrors } = useMemo(
		() => addRoleForm,
		[addRoleForm]
	);

	const handleBackModel = () => {
		setNavigation(RoleAndPermissionNavigation.CreateNewRole);
	};

	const handleAddRole = async () => {
		const formErr: IAddNewRoleFormError = {...formErrors};
		if (!name?.trim()) {
			formErr.name = 'Name is required.';
		} else if (name?.trim().length < 3) {
			formErr.name = 'Name should be at least 3 characters long.';
		} else if (!/^[a-zA-Z\s]+$/.test(name?.trim() || "")) {
			formErr.name = 'Only alphabets are allowed.';
		} else {
			formErr.name = null;
		}
		if (!description?.trim()) {
			formErr.description = 'Description is required.';
		}
		const hasAnyReadOrWritePermission = Object.keys(permissions ?? []).some(
			(category) => {
				return ['view', 'edit'].some(
					(permission) =>
						permissions[category as IPermissionType][permission as IReadWrite]
				);
			}
		);
		
		if (!hasAnyReadOrWritePermission) {
			formErr.permissions = 'Please select at least one permission for this role.';
		}

		const hasErrors = Object.values(formErr).some((error) => typeof error === 'string');
		
		if(hasErrors){
			setAddRoleForm((pre) => ({ ...pre, formErrors: formErr }));
			return
		}

		
		setCreateRoleLoading(true);
		const payload = {
			name: name.trim(),
			description: description.trim(),
			isTemplate,
			permissions: permissions,
		};
		const resp = await createUserRole(payload);

		if (resp?._id) {
			handleCloseModel();
			resetAddRoleForm();
			handleBackModel();
			successNotification(message.ROLE_SUCCESS);
		}
		setCreateRoleLoading(false);
	};

	const handleContinue = () => {
		setNavigation(RoleAndPermissionNavigation.AddNewRole);
	};

	// handle next on onclick Add role and continue button
	const handleNext = () => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				handleAddRole();
				break;
			case RoleAndPermissionNavigation.CreateNewRole:
				handleContinue();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleContinue();
				break;
			default:
				break;
		}
	};

	// handle back onclick back and cancel button
	const handleBack = useCallback(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				resetAddRoleForm();
				handleBackModel();
				resetSelectedUserRole();
				break;
			case RoleAndPermissionNavigation.CreateNewRole:
				resetAddRoleForm();
				handleCloseModel();
				resetSelectedUserRole();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleCloseModel();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	// render next button label
	const mapToNavigationNextLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Add role';
			case RoleAndPermissionNavigation.CreateNewRole:
				return 'Next';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Continue';
			default:
				return 'Next';
		}
	}, [navigate]);

	// render previous button label
	const mapToNavigationPreviousLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Back';
			case RoleAndPermissionNavigation.CreateNewRole:
				return 'Cancel';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Cancel';
			default:
				return 'Cancel';
		}
	}, [navigate]);

	const handleDeleteUserRole = useCallback(
		async (isOpen1: boolean, isOpen2: boolean) => {
			if (!isOpen1 && !isOpen2) {
				setIsUserDeleteModal(false);
				return;
			}
			setCreateRoleLoading(true);
			const resp = await deleteUserRole(selectedUserRole._id ?? '');

			if (resp) {
				successNotification(message.DELETE_MESSAGE);
				setIsUserDeleteModal(false);
				setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
				setUserRoleState((prev) => {
					const updatedList = prev?.filter(
						(el) => el._id !== selectedUserRole._id
					);
					return updatedList;
				});
			} else {
				errorNotification(resp.message);
			}
			setCreateRoleLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedUserRole]
	);

	const handleOpenDelete = useCallback((item: any | null) => {
		setIsUserDeleteModal(true);
		setSelectedUserRole(item);
	}, []);

	return {
		mapToNavigationPreviousLabel,
		mapToNavigationNextLabel,
		handleBack,
		handleNext,
		isOpenDeleteModal,
		handleDeleteUserRole,
		handleOpenDelete,
		createRoleLoading,
	};
};
