import { atom } from 'recoil';

import {
	InviteNavigation,
	RoleAndPermissionNavigation,
	UserRoleNavigation,
} from '../constants';
import {
	IAddNewRoleFormState,
	IPermissionTableData,
	IUserRoleState,
} from './type';

export const OpenDeleteModal = atom<boolean>({
	key: 'open-delete-modal-key',
	default: false,
});

export const PermissionNavigation = atom({
	key: 'permission-navigation-key',
	default: RoleAndPermissionNavigation.CreateNewRole,
});

export const AddNewRoleFormState = atom<IAddNewRoleFormState>({
	key: 'add-new-role-form-key',
	default: {
		name: null,
		description: null,
		permissions: [],
		isTemplate: false,
		formErrors:{
			name: null,
			description: null,
			permissions: null,
		}
	},
});

export const UserRolePermissionNavigation = atom({
	key: 'user-role-permission-navigation-key',
	default: UserRoleNavigation.AddedRoleCard,
});

export const InviteNavigationState = atom({
	key: 'invite-navigation-key',
	default: InviteNavigation.InviteForm,
});

export const SelectedUserRoleState = atom<IUserRoleState | null>({
	key: 'selected-user-role-key',
	default: null,
});

export const TemplateRoleState = atom<IAddNewRoleFormState[] | null>({
	key: 'template-role-state',
	default: null,
});

export const UserRoleState = atom<IUserRoleState[] | null>({
	key: 'user-role-state',
	default: null,
});

export const PermissionTableDataState = atom<IPermissionTableData[]>({
	key: 'permission-table-data-state',
	default: [],
});
